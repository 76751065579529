.Tile {
  box-shadow: 8px 8px 32px 0 #00000044;
  transition: transform 0.35s ease 0s, z-index 0.35s ease 0s;
  width: 100%;
  aspect-ratio: 1;
}

.TileContent {
  height: 100%;
}

.Tile:hover {
  z-index: 1;
  transform: scale(105%);
}
