.ShareSection {
  display: flex;
}

.ReferralLink {
  border-radius: 8px;
  padding: 12px;
  border: none;
  background: white;
  position: relative;
}

.CopyButton {
  background: #dadada;
  position: absolute;
  padding: 4px 8px;
  border-radius: 8px;
  top: 8px;
  bottom: 8px;
  right: 8px;
}

.CopyButtonCopied {
  position: absolute;
  padding: 4px 8px;
  border-radius: 8px;
  top: 8px;
  bottom: 8px;
  right: 8px;
}

.CopyButtonCopied:hover {
  color: #999ca0;
}

.ShareLogo {
  max-width: 32px;
  max-height: 32px;
}

.ShareButton {
  border-radius: 8px;
  padding: 12px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
  min-width: 80px;
}
