.App {
  display: flex;
  flex-direction: column;
}

.AppLogo {
}

.AppContent {
  display: flex;
  min-width: 240px;
}
