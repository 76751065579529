.PictureTile {
  box-shadow: 8px 8px 32px 0 #00000044;
  transition: transform 0.35s ease 0s;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  position: relative;
}

.PictureTileLabelText {
  display: flex;
  align-items: center;
  background-image: linear-gradient(#88888800, #00000088);
  position: absolute;
  height: 32px;
  width: 100%;
  bottom: 0;
}

.PictureTile:hover {
  transform: scale(105%);
}
