.CTAContainer {
  display: flex;
  align-items: center;
}

.CTAContainer .EmailInput {
  border-radius: 8px;
  padding: 12px;
  border: none;
}

.CTAContainer .EmailInput:focus {
  outline: none;
}

.CTAContainer .JoinButton {
  border-radius: 8px;
  padding: 12px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
}

.CTAContainer .JoinButton:focus {
  outline: none;
}
