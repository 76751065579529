.HorizontalContainer {
  position: relative;
  display: flex;
  flex-direction: row;
}

.TilesContainer.horizontal .HorizontalContainer {
  width: 105%; /* allow some overflow */
  margin-right: 2rem;
  margin-left: 2rem;
}

.TilesContainer.vertical .HorizontalContainer {
  margin-top: 1rem;
  margin-right: 4rem;
  margin-left: 4rem;
}

.HorizontalLine {
  border-top: 1px solid black;
  width: 100%;
}

.VerticalContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 48px;
}

.HorizontalContainer .VerticalContainer:first-child {
  margin-left: 0;
}

.VerticalContainer > *:first-child {
  margin-top: 0px;
}

.VerticalContainer > * {
  margin-top: 64px;
}